<template>
  <div id="app">
	  <a-config-provider :locale="locale">
		<router-view></router-view>
	  </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  name: 'App',
  data() {
	return {
	  locale: zhCN,
	};
  },
  created(){
	  document.getElementById("qeqan-loading").remove();
  }
}
</script>

<style>
@font-face {
    font-family: 'ALKATIP Basma Tom';
    src:url('https://mall.kartam.cn/fonts/alkatip-1.ttf') format('truetype');
	  font-weight: normal;
    font-style: normal;
    font-display: swap;
}
#app .ug{
    font-family: 'ALKATIP Basma Tom';
	  direction: rtl;
}
.gradus_180{
	transform: rotate(180deg);
	display: inline-block;
}
.mr16{margin-right: 16px;}
.ug .ant-col-1, .ug .ant-col-2, .ug .ant-col-3, .ug .ant-col-4, .ug .ant-col-5, .ug .ant-col-6, .ug .ant-col-7, .ug .ant-col-8, .ug .ant-col-9, .ug .ant-col-10, .ug .ant-col-11, .ug .ant-col-12, .ug .ant-col-13, .ug .ant-col-14, .ug .ant-col-15, .ug .ant-col-16, .ug .ant-col-17, .ug .ant-col-18, .ug .ant-col-19, .ug .ant-col-20, .ug .ant-col-21, .ug .ant-col-22, .ug .ant-col-23, .ug .ant-col-24 {
float: right;
}
.ug{
  direction: rtl;
  font-family: 'ALKATIP Basma Tom';
}
.ug .ant-modal-close{
  right: unset;
  left: 0;
}
.ug .ant-input-affix-wrapper.ug .ant-input{
  padding-left: 10px;
  padding-right: 150px;
}
.ug .ant-modal-footer button + button{
  margin-left: unset;
  margin-right: 8px;
}
</style>